.sign-up-form-container {
  display: flex;
  justify-content: center;
  margin-top: 50px;

  .sign-up-form {
    display: flex;
    flex-direction: column;
    width: 460px;
    padding: 3rem 4rem;
    margin: 4rem 0 0 0;
    box-sizing: border-box;
    border-radius: 2rem;
    background: #f1f1f2;
    box-shadow: #d1d1d2 0px 0px 40px;

    @media (max-width: 500px) {
      width: 90%;
      input {
        width: 100%;
      }
      label {
        align-self: center;
      }
    }

    h1 {
      font-size: 3rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $dark-blue;
    }
    input {
      padding: 10px;
      border: 1px solid #e1e1e2;
      border-radius: 1rem;
      outline: none;
      width: 100%;
      box-sizing: border-box;
    }
    label {
      margin-top: 10px;
      font-size: 1.6rem;
    }
    button {
      padding: 0.7em 1.7em;
      margin-top: 20px;
      text-decoration: none;
      font-family: "Roboto", sans-serif;
      font-size: 1.6rem;
      color: #ffffff;
      background-color: $dark-blue;
      text-align: center;
      border: none;
      outline: none;
      border-radius: 1rem;
      box-sizing: border-box;
    }
    .error {
      border: 1px solid rgb(94, 1, 1);
      background: rgb(253, 198, 207);
      text-align: center;
      padding: 20px;
      font-size: 1.5rem;
      border-radius: 1rem;
      margin-bottom: 1rem;
    }
    .input-error {
      font-size: 1.3rem;
      color: rgb(94, 1, 1);
      padding: 2px 1rem;
      &::before {
        content: "* ";
      }
    }
  }
}
