.home-full-bg {
  position: relative;
  .home-container {
    display: flex;
    flex-direction: column;
    align-items: space-around;
    width: 100%;
    margin: 0 auto;

    .home-image {
      background: $dark-blue url("../../img/home-bg.png") no-repeat;
      background-size: cover;
      height: 50vh;
      .home-image-inner-container {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        height: 100%;
        padding: 70px 30px 120px 30px;
        box-sizing: border-box;
        @media (max-width: 800px) {
          flex-direction: column;
          align-items: stretch;
        }
        .subtitle {
          font-style: normal;
          font-weight: 600;
          font-size: 6rem;
          color: #ffffff;
          font-family: "Open Sans", sans-serif;
          margin: 0;

          @media (max-width: 800px) {
            text-align: center;
          }

          @media (max-width: 450px) {
            font-size: 4rem;
          }
        }

        .search-form {
          align-self: flex-end;
          @media (max-width: 800px) {
            align-self: inherit;
          }
          form {
            .searchBar {
              width: 50rem;
              height: 6rem;
              background: #f8f8f8;
              border: 2px solid #000000;
              border-radius: 3rem;
              outline: none;
              padding: 0.5rem 0 0.5rem 2rem;
              font-size: 18px;
              box-sizing: border-box;

              @media (max-width: 800px) {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .search-favorites-container {
      padding: 5rem;
      display: flex;
      justify-content: space-between;
      @media (max-width: 700px) {
        flex-direction: column;
      }
    }
  }
}

.search-template,
.favorite-template {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 40px;
  box-sizing: border-box;
  @media (max-width: 500px) {
    padding: 0;
  }
}

.favorite-template {
  align-self: flex-end;
}

.search-template i,
.favorite-template i {
  font-size: 110px;
  margin: 0 0 20px 0;
  color: $dark-blue;
}

.search-template span,
.favorite-template span {
  font-size: 3rem;
  font-weight: bold;
  margin-left: 10px;
  color: $dark-blue;

  @media (max-width: 400px) {
    font-size: 2.5rem;
  }
}
.search-template p,
.favorite-template p {
  font-size: 1.7rem;
  color: $dark-blue;
}
footer {
  height: 80px;
  width: 100%;
  background-color: #141534;
}
.footer-content {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  font-size: 20px;
}
