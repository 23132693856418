body {
  font-family: "Open Sans", sans-serif;
}

.title {
  font-family: "Permanent Marker", cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 9rem;
  color: white;
  margin: 0;
  position: absolute;
  top: 0.5rem;
  left: 33%;

  @media (max-width: 980px) {
    font-size: 6rem;
    top: 3rem;
  }
  @media (max-width: 750px) {
    font-size: 5rem;
  }

  @media (max-width: 580px) {
    font-size: 4rem;
  }
}
