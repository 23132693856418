.search-results {
  height: 100%;
  & > form {
    background: $dark-blue url("../../img/home-bg.png") no-repeat;
    background-size: cover;
    height: 10rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 0;
    .searchBar {
      font-size: 2rem;
      padding: 1.6rem 3rem;
      border-radius: 3rem;
      width: 50%;
      max-width: 550px;
      border: 1px solid #e1e1e2;
      outline: 0;
      box-sizing: border-box;
      @media (max-width: 800px) {
        width: 70%;
      }
      @media (max-width: 500px) {
        width: 80%;
      }
    }
  }
  .results-list {
    max-width: 1024px;
    box-sizing: border-box;
    min-height: 70%;
    margin: 20px auto;
    background: #f1f1f2;
    padding: 10px;
    border-radius: 20px;
    & > p {
      font-size: 1.6rem;
    }
    .results-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      h1 {
        font-size: 2.4rem;
        padding: 0 0 0 1rem;
      }
      input {
        padding: 10px 20px;
        border: 1px solid #e1e1e2;
        border-radius: 5px;
        width: 20%;
        transition: all 0.2s;
        outline: 0;
        &:focus {
          width: 30%;
        }
        @media (max-width: 700px) {
          width: 40%;
          &:focus {
            width: 50%;
          }
        }
      }
    }

    .no-favorites {
      font-size: 1.6rem;
    }

    .tempo,
    .popularity {
      @media (max-width: 700px) {
        display: none;
      }
    }
    .artist,
    .duration {
      @media (max-width: 500px) {
        display: none;
      }
      h1 {
        font-size: 2.4rem;
      }
    }
  }
}
