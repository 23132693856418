.header-container {
  background-color: #141534;
}

.header-top {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 15rem;
  @media (max-width: 500px) {
    height: 10rem;
  }
}

.logo {
  width: 150px;
  padding: 0 0 0 3rem;
  @media (max-width: 500px) {
    width: 90px;
    align-self: center;
  }
}
.header-links-container {
  text-align: right;
}

.header-links {
  margin: 10px 30px 10px 10px;
  a {
    color: white;
    text-decoration: none;
    font-size: 1.8rem;
    margin: 10px;
    border: 1px solid transparent;
    border-radius: 20px;
    padding: 10px 18px;
    transition: all 0.4s ease;
    @media (max-width: 500px) {
      font-size: 1.4rem;
      padding: 5px 10px;
      border-radius: 12px;
    }
    &:hover {
      background-color: #c889ff;
    }
  }

  .active {
    border: 1px solid #fff;
  }
  i {
    font-size: 25px;
    color: white;
    cursor: pointer;
  }
}

.header-links .links-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  right: 100px;
  background: rgb(0, 0, 0.4);
  border-radius: 20px;
  z-index: 10;
}

.header-title {
  color: white;
  height: 0;
  font-size: 18px;
  text-align: center;
  background: white;
}

.header-title h1 {
  margin: 0;
  font-size: 40px;
}
