body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
//     monospace;
// }

html,
body,
#root,
.App {
  height: 100%;
}

.App {
  overflow-x: hidden;
}

html {
  font-size: 62.5%;
  box-sizing: border-box;
}
