.profile-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 50px;

  h1 {
    font-size: 30px;
  }

  .profile-info {
    display: flex;
    flex-direction: column;
    i {
      font-size: 200px;
    }
  }
}

.profile-favorite-songs {
  box-shadow: 0 4px 8px 0 rgb(169, 169, 169);
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin: 10%;
    padding: 30px;
    width: 150px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
      background-color: $dark-blue;
    }
  }

  h3 {
    font-size: 20px;
  }

  ul {
    margin: 10px;
  }

  li {
    font-size: 16px;
    list-style-type: disc;
    margin: 6px;
  }
}
